<template>
  <div class="sharecard_model" :class="'meeting_'+_sid" :id="`sharecard_model_${_kind}_${_cardid}_${_id}`"
  >
  <!-- 
  :style="_kind==='schedule'?`top: 0; left: ${$route.name=='Poster'?'10px':'-48vw'}; height: 100vh; overflow: auto;`:''" 
  -->
    <!-- _year eg.'2024sz' -->
    <slot v-if="_kind && renderData.data && _year==='2024sz'">
      <div class="poster-container" style="background-image:url(https://f.msup.com.cn/poster-img/poster-body-background-giac2024.png);">
        <!-- <img class="poster-header" :src="getRenderImg('header')" alt="" crossOrigin="anonymous"> -->
        <img class="poster-header" :src="getRenderImg('header')" alt="">
        <div :class="`poster-body poster-body-${_kind}`" :style="_kind!=='schedule'?`background-image:url(${getRenderImg('middle')});`:''">
          <div class="content-background" v-if="renderData.data.paramRender">
            <slot v-for="(img, index) in renderData.data.paramRender['center']['main']">
              <img :src="img.url" alt="" :key="'r-c-m-'+index">
            </slot>
          </div>
          <slot v-if="renderData.data.paramUncertain && renderData.data.paramUncertain.data">
            <!-- 专题 -->
            <div v-if="checkKind('subject')" class="poster-content content-subject" :id="`subject_param${getRenderData('venue','svId')}`">
              <div class="subject-detail">
                <h1 class="poster-font-f-ali-m poster-font-w-700">专题：{{ getRenderData('venue','venueTitle') }}</h1>
              </div>
              <slot v-for="(producer, index) in getRenderData('venue','producers')">
                <div v-if="index==0" class="subject-lecturer" :id="`subject_lecturer_param${producer.id}`" :key="'r-d-s-'+index">
                  <h3 class="poster-font-f-ali-m poster-font-w-700">出品人</h3>
                  <h2 class="poster-font-f-ali-b poster-font-w-700">{{ producer.name||'' }}</h2>
                  <p class="poster-font-w-500">{{ producer.company }}</p>
                  <p class="poster-font-w-500">{{ producer.office||'' }}</p>
                  <p class="poster-font-w-500">{{ producer.position }}</p>
                  <img v-if="producer.thumbs" :src="checkThumbs(producer.thumbs)" alt="">
                </div>
              </slot>
              <div class="subject-courses">
                <div v-for="(course, index) in getRenderData('venue','courses')" class="subject-course" :key="'r-d-s-c-'+index">
                  <div class="img-content">
                    <img v-if="course.logo" :src="checkThumbs(course.logo)" alt="">
                  </div>
                  <h3 class="poster-font-f-ali-m poster-font-w-500">{{ course.courseTitle }}</h3>
                  <slot v-for="(lecturer, lindex) in course.lecturers">
                    <div v-if="lecturer" class="subject-course-lecturer flex-con" :key="'r-d-s-c-'+index+'-l-'+lindex">
                      <p class="poster-font-f-ali-m- poster-font-w-500">{{ lecturer.name }}</p>
                      <p class="poster-font-f-ali-m- poster-font-w-500">
                        <span class="poster-font-f-ali-m- poster-font-w-500">{{ lecturer.company }} {{ lecturer.office||'' }}</span>
                        <span class="poster-font-f-ali-m- poster-font-w-500">{{ lecturer.position }}</span>
                      </p>
                    </div>
                    <div v-else class="subject-course-lecturer flex-con" :key="'r-d-s-c-'+index+'-l-'+lindex">
                    </div>
                  </slot>
                </div>
              </div>
            </div>
            <!-- 课程 -->
            <div v-if="checkKind('course')" class="poster-content content-course" :id="`course_param${getRenderData('course','courseId')}`" :style="`--title-background-img: url(${getRenderImg('center','title')||''})`">
              <!-- <slot v-for="(lecturer, index) in getRenderData('course','lecturers')">
                <div class="course-lecturer flex-con" :id="'lecturer_param'+lecturer.id" :key="'r-d-c-'+index">
                  <div class="img-content ring-circle">
                    <img v-if="lecturer.thumbs" class="ring-circle" :src="checkThumbs(lecturer.thumbs)" alt="">
                  </div>
                  <div class="info">
                    <h2 class="poster-font-w-bold">{{ lecturer.name }}</h2>
                    <p class="poster-font-w-400">{{ lecturer.company }}</p>
                    <p class="poster-font-w-400">{{ lecturer.office||'' }}</p>
                    <p class="poster-font-w-400">{{ lecturer.position }}</p>
                  </div>
                </div>
              </slot> -->
              <div class="course-lecturer flex-con" v-if="getRenderData('course','teacher')" :id="'lecturer_param'+getRenderData('course','teacher').id">
                <div class="img-content ring-circle">
                  <img v-if="getRenderData('course','teacher').thumbs" class="ring-circle" :src="checkThumbs(getRenderData('course','teacher').thumbs)" alt="">
                </div>
                <div class="info">
                  <h2 class="poster-font-w-bold">{{ getRenderData('course','teacher').name }}</h2>
                  <p class="poster-font-w-400">{{ getRenderData('course','teacher').company }}</p>
                  <p class="poster-font-w-400">{{ getRenderData('course','teacher').office||'' }}</p>
                  <p class="poster-font-w-400">{{ getRenderData('course','teacher').position }}</p>
                </div>
              </div>
              <div class="course-subject">
                <h1 class="main-title poster-font-w-bold" style="background-image:var(--title-background-img);">
                  专题: {{ getRenderData('course','schedulingVenueTitle') }}
                </h1>
              </div>
              <div class="course-detail" :id="'course_detail_param'+getRenderData('course','courseId')">
                <h2 class="poster-font-w-bold">{{ getRenderData('course','courseTitle') }}</h2>
                <!-- <h3 class="title">课程概要</h3> -->
                <p class="desc" v-html="getRenderData('course','courseDesc')"></p>
                <h3 class="main-title title poster-font-w-bold" style="background-image:var(--title-background-img);">听众收益</h3>
                <p class="profit" v-html="getRenderData('course','courseProfit')"></p>
              </div>
            </div>
            <!-- 案例 -->
            <div v-if="checkKind('case')" class="poster-content content-cases" :id="`case_param${selectedparams.cardDetailId}`">
              <div class="cases-title">
                <!-- poster-font-f-ali-h -->
                <h1 class="poster-font-f-ali-b poster-font-w-bold">部分入围案例</h1>
              </div>
              <div v-for="(item,key) in getRenderData('case')" class="cases-course flex-con" :id="`cases_course_param${item.courseId}`" style="background-image:url('https://f.msup.com.cn/poster-bg-case-lecturer.png');" :key="'r-c-'+key">
                <div class="img-content ring-circle">
                  <img v-if="item.lecturers && item.lecturers[0] && item.lecturers[0].thumbs" :src="checkThumbs(item.lecturers[0].thumbs)" alt="">
                </div>
                <div class="cases-course-detail">
                  <h3 class="poster-font-f-ali-b poster-font-w-bold">{{ item.courseTitle }}</h3>
                  <p class="poster-font-w-400" v-if="item.lecturers && item.lecturers[0]">{{ item.lecturers[0].name }} | {{ item.lecturers[0].office||'' }} {{ item.lecturers[0].position }}</p>
                </div>
              </div>
            </div>
            <!-- 日程 -->
            <div v-if="checkKind('schedule')" class="poster-content content-schedule" :id="`schedule_param${selectedparams.scheduleId}`">
              <h1 class="schedule-title poster-font-f-ali-m poster-font-w-500">
                会议日程-第{{ global.numberList[selectedparams.scheduleId] }}天
                <p v-if="_origindata.date" class="poster-font-f-ali-m poster-font-w-500">
                  {{ global.formatTime(_origindata.date, 'Y.M.D', 1) }}
                  {{ global.getWeek(_origindata.date) }}
                </p>
              </h1>
              <slot v-for="(period, pkey) in _origindata.datas">
                <slot v-for="(group, gkey) in period">
                  <!-- venueName producers||name schedulingCourse  -->
                  <div class="schedule-subject" v-if="group.schedulingCourse" :key="'r-s-p-'+pkey+'-s-'+gkey" :style="`background-image:url(${getRenderImg('middle')});`">
                    <div class="schedule-subject-detail">
                      <h1 class="poster-font-f-ali-m poster-font-w-500">
                        {{ group.venueName||'敬请期待' }}
                        <span></span>
                        <span></span>
                        <span></span>
                      </h1>
                      <h2 class="poster-font-f-ali-m poster-font-w-500">出品人<span>|</span>{{ group.producers||group.name||'神秘大咖' }}</h2>
                      <div class="schedule-subject-course flex-con" v-for="(course, cindex) in group.schedulingCourse.sort(global.toSortKey('startTime',1,'string'))" :key="'r-s-p-'+pkey+'-s-'+gkey+'-c-'+cindex">
                        <p class="s-course-times">
                          {{ course.startTime[0]=='0'?course.startTime.slice(1):course.startTime }}<span>-</span>{{ course.endTime }}
                        </p>
                        <div class="s-course-detail">
                          <h2 class="poster-font-f-ali-m poster-font-w-500">{{ course.courseTitle }}</h2>
                          <div class="s-course-lecturer flex-con">
                            <div class="img-content ring-circle">
                              <img class="ring-circle" :src="course.thumbs" v-if="course.thumbs" alt="">
                            </div>
                            <h4>
                              {{ course.name }}
                              <p>{{ course.company+' ' }}{{ course.position }}</p>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </slot>
              </slot>
            </div>
          </slot>
        </div>
        <img class="poster-footer" :src="getRenderImg('footer')" alt="">
      </div>
    </slot>
    <slot v-if="_kind && renderData.data && _year==='2025sz'">
      {{ _year }} {{ _kind }} <br> {{ selectedparams }} <br><br>
      {{ renderData }} <br><br>
      {{ _origindata }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Renders',
  props: {
    _selectparams: [Object, Array, String],
    _activedata: [Object, Array, String],
    _origindata: [Object, Array],
  },
  computed: {
    _sid() {
      return this.selectedparams.sid;
    },
    _year() {
      return this.selectedparams.year;
    },
    _kind() {
      return this.selectedparams.type && this.selectedparams.type.toLocaleLowerCase();
    },
    _cardid() {
      return this.selectedparams.cardId;
    },
    _id() {
      let id = this.selectedparams.cardDetailId || ( // 》》》》》》
        (this.selectedparams.type=='chairman'||this.selectedparams.type=='producer')&&this.selectedparams.lecturerId
      ) || (
        this.selectedparams.type=='subject'&&this.selectedparams.venueId
      ) || (
        this.selectedparams.type=='course'&&this.selectedparams.courseId
      ) || (
        this.selectedparams.type=='case'&&'case'
      ) || (
        (this.selectedparams.type=='schedule'&&this.selectedparams.scheduleId!==false) ? this.selectedparams.scheduleId : false
      );
      if(!id || id===0 || id==='0') {
        id = false;
      }
      console.log('_id', id, this.selectedparams)
      return id;
    },
    basedata() {
      return {_id:this._id, ...this.activeData};
    }
  },
  data() {
    return {
      selectedparams: {}, // { year:false, sid:false, type:false, cardId:false, cardDetailId:false, venueId:false, courseId:false, scheduleId:false },
      renderData: {},
      activeData: {},
    }
  },
  watch: {
    _selectparams: {
      handler(_new, _old) {
        // console.log('Renders·watch·_selectparams-- ', _new, _old);
        // { year:false, sid:false, type:false, cardId:false, cardDetailId:false, venueId:false, courseId:false, scheduleId:false };
        if(_new) {
          if(typeof _new==='string' && _new.includes('{')){
            this.selectedparams = JSON.parse(_new);
          } else {
            this.selectedparams = _new;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // selectedparams: {
    //   handler(_new, _old) {
    //     console.log('Renders·watch·selectedparams-- ', _new, _old);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    _activedata: {
      handler(_new, _old) {
        // console.log('Renders·watch·_activedata-- ', _new); // _new, _old
        if(_new) {
          let act = {};
          if(typeof _new==='object') {
            act = this.global.deepClone(_new);
          }
          if(typeof _new==='string' && _new.includes('{')) {
            act = JSON.parse(_new);
          }
          this.activeData = {...act};
        }
      },
      immediate: true,
      deep: true,
    },
    // _origindata: {
    //   handler(_new, _old) {
    //     // console.log('Renders·watch·_origindata-- ', _new, _old);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    basedata: {
      handler(_new, _old) {
        // console.log('Renders·watch·basedata-- ', _new, _old);
        if(_new && typeof _new === 'object' && _new.sid && (_new._id||_new._id===0||_new._id==='0')) {
          // console.log('Renders·watch·basedata--1');
          if(!_old || Object.keys(_old).length<=1 || _old._id!==_new._id) {
            // console.log('Renders·watch·basedata--2');
            this.formatModuleData(_new);
          }
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    // v-if切换组件类型
    checkKind(type) {
      let flag = false;
      if(
        this._kind.toLowerCase()===type && 
        this.renderData.data && 
        this.renderData.data.paramUncertain && 
        this.renderData.data.paramUncertain.data
      ) {
        // console.log(type, this.renderData.data.paramUncertain.data)
        // if((type==='subject' && this.renderData.data.paramUncertain.data['venue'])) {
        if(
          (type==='subject' && this.renderData.data.paramUncertain.data['venue']) ||
          (type==='course' && this.renderData.data.paramUncertain.data['course']) ||
          (type==='case' && this.renderData.data.paramUncertain.data['case']) ||
          (type==='schedule' && this.renderData.data.paramUncertain.data['schedule']) ||
          (type==='chairman' && this.renderData.data.paramUncertain.data['chairman']) ||
          (type==='producer' && this.renderData.data.paramUncertain.data['producer'])
        ) {
          flag = true;
        }
      }
      return flag;
    },
    // 头像数据检查
    checkThumbs(thumbs) {
      let file = thumbs;
      if(thumbs.includes('{') && file.includes('}') && JSON.parse(thumbs)) {
        if(Array.isArray(JSON.parse(thumbs)) && JSON.parse(thumbs).length>0) {
          file = JSON.parse(thumbs)[0].fileUrl;
        } else {
          file = JSON.parse(thumbs).fileUrl;
        }
      }
      return file;
    },
    // 获取背景图
    getRenderImg(key1, key2) {
      let res = '';
      if(!key2) {
        res = this.renderData.data.paramRender && this.renderData.data.paramRender[key1] || '';
      } else {
        res = this.renderData.data.paramRender && this.renderData.data.paramRender[key1] && this.renderData.data.paramRender[key1][key2] || '';
      }
      return res;
    },
    getRenderData(key1, key2) {
      let res = '';
      if(this.renderData.data.paramUncertain && this.renderData.data.paramUncertain.data) {
        if(!key2) {
          res = this.renderData.data.paramUncertain.data[key1] || '';
        } else {
          res = this.renderData.data.paramUncertain.data[key1] && this.renderData.data.paramUncertain.data[key1][key2] || '';
        }
      }
      return res;
    },

    // 格式化数据->获取图片和文字数据->renderData
    formatModuleData(_baseData) {
      let baseData = this.global.deepClone(_baseData);
      let type = this._kind;
      let renderList = {
        // body: '',
        header: baseData.topBackground,
        middle: baseData.middleBackground && JSON.parse(baseData.middleBackground) && JSON.parse(baseData.middleBackground)[0] && JSON.parse(baseData.middleBackground)[0].file || '',
        center: {
          main: baseData.middleBackground && JSON.parse(baseData.middleBackground) && baseData.middleBackground && JSON.parse(baseData.middleBackground).length>1 ? (arr => {
            let res = [];
            for(let index in arr) {
              if(index > 0) {
                res.push({ 
                  url: arr[index].file, 
                  repeat: arr[index].isRrepeat&&arr[index].isRrepeat!=='0' ? true : false, 
                  sort: arr[index].topToBottomSort, // 排序
                  zIndex: arr[index].frontToBackSort, // z-index
                  expand: arr[index].isExpand&&arr[index].isExpand!=='0' ? true : false, // 拉伸
                });
              }
            }
            if(res.length > 1) {
              res.sort(this.global.toSortKey('sort', 1, 'string'));
            }
            return res;
          })(JSON.parse(baseData.middleBackground)) : [],
          title: baseData.subTitleBackground || '',
        },
        footer: baseData.bottomBackground || '',
      };
      // let data = typeof baseData==='object' ? { ...baseData } : '';
      let list = {};
      let logoList = baseData.logo && JSON.parse(baseData.logo) || [];
      let thumbsList = baseData.thumbs && JSON.parse(baseData.thumbs) || [];
      console.log('------ ', baseData, this._origindata, list)
      if (type == 'chairman' || type == 'producer') {
        list = {}
        list[type] = { ...baseData.lecturer //...this._origindata // 》》》》》》
          // id: item.id || item.content || '',
          // penName: item.name || item.content_note || '',
          // name: item.name || item.content_note || '',
          // company: item.company || '',
          // office: item.office || item.department || '',
          // position: item.position || '',
          // thumbsList: item.file || item.thumbs || '',
          // thumbs: this.global.formatThumbs(item.file) || this.global.formatThumbs(item.thumbs) || ''
        }
      }
      // subject、course、case    schedule使用_origindata不需要在此处获取列表数据
      if(type == 'subject') {
        list = { 'venue': baseData.venue };
        if(list.venue && this._origindata) {
          let originData = this._origindata;
          // 未获取到producers时，使用_origindata的producer相关字段
          if((!list.venue.producers || list.venue.producers.length==0) && originData) {
            // if(originData.venue && originData.venue.producers) {
            //   list.venue.producers = originData.venue.producers;
            // } else {
              list.venue.producers = [{
                company: originData.company || '',
                id: originData.producerId || '',
                name: originData.name || '',
                office: originData.office || originData.department || '',
                penName: originData.penName || '',
                position: originData.position || '',
                thumbs: originData.thumbs || ''
              }];
            // }
          }
          // 未获取到courses时，使用_origindata的schedulingCourse字段
          if(!list.venue.courses || list.venue.courses.length==0) {
            // if(originData.venue && originData.venue.courses) {
            //   list.venue.courses = originData.venue.courses;
            // } else 
            if(originData.schedulingCourse && originData.schedulingCourse.length>0) {
              list.venue.courses = [];
              for(let i in originData.schedulingCourse) {
                list.venue.courses.push({
                  courseId: originData.schedulingCourse[i].courseid || '',
                  courseTitle: originData.schedulingCourse[i].courseTitle || '',
                  lecturers: [{
                    company: originData.schedulingCourse[i].company || '',
                    id: '',
                    name: originData.schedulingCourse[i].name || '',
                    office: originData.schedulingCourse[i].office || originData.schedulingCourse[i].department || '',
                    penName: null,
                    position: originData.schedulingCourse[i].position || ''
                  }],
                  logo: originData.schedulingCourse[i].logo || ''
                });
              }
            }
          }
        }
        // producer头像获取
        for(let thumbs of thumbsList) {
          for(let index in list.venue.producers) {
            if(list.venue.producers[index].id == thumbs.lecturerId) {
              list.venue.producers[index].thumbs = thumbs.file;
            }
          }
        }
        // 公司logo获取
        for(let logo of logoList) {
          for(let index in list.venue.courses) {
            for(let i in list.venue.courses[index].lecturers) {
              if(list.venue.courses[index].lecturers[i].id == logo.lecturerId) {
                list.venue.courses[index].logo = logo.file;
              }
            }
          }
        }
        // office字段判断-producer
        if(list.venue && list.venue.producers) {
          for(let index in list.venue.producers) {
            if(!list.venue.producers[index].office || list.venue.producers[index].office=='') {
              if(list.venue.producers[index].position && list.venue.producers[index].position.includes(' ')) {
                let position = list.venue.producers[index].position;
                list.venue.producers[index].office = position.split(' ')[0];
                list.venue.producers[index].position = position.split(' ').pop();
              }
            }
          }
        }
        // office字段判断-courses
        if(list.venue && list.venue.courses) {
          for(let index in list.venue.courses) {
            for(let i in list.venue.courses[index].lecturers) {
              if(list.venue.courses[index].lecturers[i] && (!list.venue.courses[index].lecturers[i].office || list.venue.courses[index].lecturers[i].office=='')) {
                if(list.venue.courses[index].lecturers[i].position && list.venue.courses[index].lecturers[i].position.includes(' ')) {
                  let position = list.venue.courses[index].lecturers[i].position;
                  list.venue.courses[index].lecturers[i].office = position.split(' ')[0];
                  list.venue.courses[index].lecturers[i].position = position.split(' ').pop();
                }
              }
            }
          }
        }
      }
      if(type == 'course') {
        list = { 'course': baseData.course };
        list.course.courseDesc = baseData.courseDesc || list.course && list.course.courseDesc && list.course.courseDesc.replace(/\t/g, '').replace(/\r\n\r\n/g, '\r\n') || '';
        list.course.courseProfit = baseData.courseProfit || list.course && list.course.courseProfit || '';
        // 讲师头像
        for(let thumbs of thumbsList) {
          for(let index in list.course.lecturers) {
            if(!list.course.lecturers[index]) {
              list.course.lecturers[index] = {
                company: '',
                id: '',
                name: '',
                office: '',
                penName: '',
                position: '',
                thumbs: '',
              };
            }
            if(list.course.lecturers[index].id == thumbs.lecturerId) {
              list.course.lecturers[index].thumbs = thumbs.file;
            }
          }
        }
        // 拆解讲师字段(应对多讲师的情况)
        list.course.teacher = {}
        let teacherId = this._origindata && this._origindata.teacher && this._origindata.teacher[0] && this._origindata.teacher[0].id
        if (list.course.lecturers && teacherId) {
          for (let item of list.course.lecturers) {
            if (item.id == teacherId) {
              list.course.teacher = item;
              break;
            }
          }
        }
      }
      if(type == 'case') {
        list = { 'case': baseData.case };
        for(let k in list.case) {
          for(let index in list.case[k].lecturers) {
            if(!list.case[k].lecturers[index].office || list.case[k].lecturers[index].office=='') {
              if(list.case[k].lecturers[index].position && list.case[k].lecturers[index].position.includes(' ')) {
                let position = list.case[k].lecturers[index].position;
                list.case[k].lecturers[index].office = position.split(' ')[0];
                list.case[k].lecturers[index].position = position.split(' ').pop();
              }
            }
            for(let thumbs of thumbsList) {
              if(list.case[k].lecturers[index].id == thumbs.lecturerId) {
                list.case[k].lecturers[index].thumbs = thumbs.file;
              }
            }
          }
        }
      }
      if(type == 'schedule') {
        list = { 'schedule': {id:this.selectedparams.scheduleId} };
        // let scheduleClone = this.global.deepClone(this._origindata) || {};
        // list = { 'schedule': scheduleClone };
        // list.schedule.id = this.selectedparams.scheduleId;
        // // list = { 'schedule': {id:this.selectedparams.scheduleId, ...scheduleClone} };
      }
      let dataList = { type: type, data: {...list, isback: true}};
      this.renderData = {
        // moduleHtml: { 
        //   body: baseData.template || '', // 模版html(暂为无效字段,待改进)
        //   child: '',
        // },
        data: {
          paramRender: renderList, // 图片类数据
          paramUncertain: dataList, // 文字/头像/logo类数据
        }, 
      };
      // console.log('------------》》》', dataList, this._origindata);
      this.$emit('isready',{status:'200', data:dataList });
    }
  }
}
</script>

<style lang="scss" class="sharecard_model_style">
@import '../../assets/css/poster.css';
</style>